<template>
  <div class="client-info box">
    <div class="columns is-multiline">
      <div class="column is-one-third">
        <h6>Фамилия:</h6>
        <p>{{ clientData.lastName }}</p>
      </div>
      <div class="column is-one-third">
        <h6>Имя:</h6>
        <p>{{ clientData.firstName }}</p>
      </div>
      <div class="column is-one-third">
        <h6>Пол:</h6>
        <p>{{ Number(clientData.gender) ? 'Женский' : 'Мужской' }}</p>
      </div>
      <div class="column is-one-third">
        <h6>
          {{ clientData.isPassport ? 'Серия паспорта:' : 'Серия документа' }}
        </h6>
        <p>{{ clientData.passportSeries }}</p>
      </div>
      <div class="column is-one-third">
        <h6>
          {{ clientData.isPassport ? 'Номер паспорта:' : 'Номер документа' }}
        </h6>
        <p>{{ clientData.passportNumber }}</p>
      </div>
    </div>
    <GroupButtons :id="clientData.id" :type="'non-resident'" />
  </div>
</template>

<script>
import GroupButtons from './common/GroupButtons';

export default {
  name: 'NonResident',
  props: ['clientData'],
  components: {
    GroupButtons,
  },
};
</script>
